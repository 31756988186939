import React from "react";
import { Helmet } from "react-helmet";

import {
  Landing,
  Skills,
  Education,
  Contacts,
  Projects,
  Achievement,
} from "../../components";
import { headerData } from "../../data/headerData";

function Main() {
  return (
    <div>
      <Helmet>
        <title>{headerData.name}</title>
      </Helmet>

      <Landing />
      {/* <Projects /> */}
      {/* <Education />             */}
      {/* <Achievement /> */}
      {/* <Blog /> */}
      {/* <Contacts /> */}
      {/* <Footer /> */}
    </div>
  );
}

export default Main;
